import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { News } from 'src/app/_models/News';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { Category } from 'src/app/_models/Category';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-tobic-details',
  templateUrl: './tobic-details.component.html',
  styleUrls: ['./tobic-details.component.scss']
})
export class TobicDetailsComponent implements OnInit {
public tobicId:string;
releatedTopicsData:any;
public tobicData:any;
//public newsReletedList:any=[];
public videoLink:string='';
public categoriesList: Array<Category> =new Array<Category>();
  constructor(private activatedRoute: ActivatedRoute, private mainService: MainService, private sanitizer: DomSanitizer) {
 
    
   }

  ngOnInit(): void {
 
    this.activatedRoute.params.subscribe(
      params => {
        this.tobicId= params['id'];
        this.mainService.getTobicById(this.tobicId).subscribe(
          data => {
            console.log(  "  data",data);
            this.tobicData = JSON.parse(JSON.stringify(data));
            if(this.tobicData.ImagePath  != null){
              this.tobicData.ImageSrc = environment.apiUrl + `/Image/${ this.tobicData.ImagePath}`;//this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,`+ Buffer.from(this.newsData.ImageData).toString('base64'));
              this.tobicData.AuthorImageSrc = environment.apiUrl + `/Image/${ this.tobicData.AuthorImage}`;
              if(this.tobicData.NewsVideoURL != null && this.tobicData.NewsVideoURL != ''){
                let linkCode = this.tobicData.NewsVideoURL.split("=")[1];
                this.videoLink ="https://www.youtube.com/embed/"+ linkCode;
              }
              
              
        }else{
              this.tobicData.ImageSrc ="assets/img/noimage.png";
              this.tobicData.AuthorImageSrc  ="assets/img/noimage.png";
             }
             if(this.tobicData.CategoryId !=null){
              this.mainService.getAllTobics(Number.parseInt(this.tobicData.CategoryId)).subscribe(
                data=>{
                 let returnedData=  JSON.parse(JSON.stringify(data));
                  this.releatedTopicsData=returnedData.filter(a=>a.SubjectId !=  this.tobicId);
                  this.releatedTopicsData.forEach(x => {
                    if (x.ImagePath != null) {
                      x.ImageSrc = environment.apiUrl + `/Image/${x.ImagePath}`;
                    } else {
                      x.ImageSrc = "assets/img/noimage.png";
                    }
                  });
                   
                },error =>{
                  console.log( "getLatestNews",error);
                }
              );
            }
          },
          error => {
            // Handle errors
            console.log(  "   this.combinedData error",error);
          }  
        );

      });
   


  }



}
