import {
 
  AfterViewInit,
    Component,
    ElementRef,
 
    OnInit,
    ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
 
import { forkJoin } from "rxjs";
import { Category, CategoryNews } from "src/app/_models/Category";
import { News } from "src/app/_models/News";
import { MainService } from "src/app/_services/main.service";
import { Buffer } from 'buffer';
import { Video } from "src/app/_models/Video";
 import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment.prod";
 
  
 
  
 
 

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
    providers: [NgbCarouselConfig]

})

export class HomeOneComponent implements OnInit  {
  

  breakingNewsItems: any;
  currentIndex = 0;
  public categoriesList: Array<Category> =new Array<Category>();
  categoryListSource: Array<string> = [];
  CategoryNewsData: Array<CategoryNews> =new Array<CategoryNews>();
  advsTwo:any;
  advsThree:any;
   latestNews:any;
   sliderNews: any
 homeNews:any;
 TopicsData:any;
 companyData:any;
 videosData: Array<Video>=[];
 public liveLink:string= '';
 public firstVideoLink:any;
 //firstVideoName:string="";
 dataFetchingInProgress: boolean = true;
 public firstTobicData:any;
 
    constructor( private mainService: MainService , private sanitizer: DomSanitizer, config: NgbCarouselConfig) {
    	config.interval = 6000;
		config.wrap = true;
		config.keyboard = false;
		config.pauseOnHover = true;
    config.showNavigationArrows = true;
		config.showNavigationIndicators = true;
    
    }
   

    async ngOnInit() {
      try {
        // Fetching all data concurrently using Promise.all()
        const [categoriesList, categoryNews, liveData, latestNews, breakingNewsItems, videosData, advTwoData,advThreeData] = await Promise.all([
          this.mainService.getCategoryALlData().toPromise(),
          this.mainService.getCategoryNews().toPromise(),
          this.mainService.getLiveVideo().toPromise(),
          this.mainService.getLatestNews().toPromise(),
          this.mainService.getImportantNews().toPromise(),
          this.mainService.getVideos().toPromise(),
          this.mainService.GetAdsByOrder(2).toPromise(),
          this.mainService.GetAdsByOrder(3).toPromise(),
         
        ]);
     
        // Further processing with each data
 
     
        // Modify data as needed
        const modifiedLatestNews = latestNews.map(x => ({
          ...x,
          ImageSrc: x.ImagePath ? `${environment.apiUrl}/Image/${x.ImagePath}` : "assets/img/noimage.png",
          CategoryName: categoriesList.find(a => a.CategoryId == x.CategoryId)?.CategoryTitle
        }));
       
        // Assign other data to class properties
        this.categoriesList = categoriesList;
        this.CategoryNewsData = categoryNews;
        
        this.liveLink = `https://www.youtube.com/embed/${liveData[0].LiveVideoUrl.split("=")[1]}?autoplay=1`;
        this.breakingNewsItems = breakingNewsItems;
         this.sliderNews = modifiedLatestNews.filter(a=>a.ViewInBanner==1).splice(0, 5);
        this.videosData = videosData.splice(0, 4);
        
        this.videosData.forEach(x => {
          if (x.ImagePath != null) {
            x.ImageSrc = environment.apiUrl + `/Image/${x.ImagePath}`;
          } else {
            x.ImageSrc = "assets/img/noimage.png";
          }
        });
       // this.videosData = this.videosData.splice(0, 4);
        //const linkCode = this.videosData[0].VideoURL.split("=")[1];
      //  this.firstVideoLink = "https://www.youtube.com/embed/" + linkCode + "?autoplay=0";
        this.firstVideoLink = this.videosData[0];
 
        // Assign modified latestNews data to class property
        this.latestNews = modifiedLatestNews.splice(0, 14);
       this.advsTwo= advTwoData;
       this.advsThree= advThreeData;
        if(this.advsTwo != null){
          this.advsTwo.link= this.advsTwo.VideoURL;
          if ( this.advsTwo.ImagePath != null) {
                  
            this.advsTwo.ImageSrc=  environment.apiUrl + `/Image/${this.advsTwo.ImagePath}`; 
          } else {
            
            this.advsTwo.ImageSrc = "assets/img/news/Video21290-180.gif";
          }
         }
            
            if(this.advsThree != null){
              this.advsThree.link= this.advsThree.VideoURL;
              if ( this.advsThree.ImagePath != null) {
                      
                this.advsThree.ImageSrc=  environment.apiUrl + `/Image/${this.advsThree.ImagePath}`; 
              } else {
                
                this.advsThree.ImageSrc = "assets/img/news/Video1728-90.gif";
              }
             }
                
        console.log("this.latestNews", this.latestNews);
      
        // Further processing if needed
    this.selectTopic("1");
      } catch (error) {
        console.error("Error:", error);
      }
    }


    selectTopic(tabId: string) {
      
      this.mainService.getAllTobics(Number.parseInt(tabId)).subscribe(
        data=>{
       
          this.TopicsData=  JSON.parse(JSON.stringify(data));
          console.log();
          this.TopicsData.forEach(x => {
            if (x.ImagePath != null) {
              x.ImageSrc = environment.apiUrl + `/Image/${x.ImagePath}`;
              x.AuthorImageSrc=  environment.apiUrl + `/Image/${x.AuthorImage}`; 
            } else {
              x.ImageSrc = "assets/img/noimage.png";
              x.AuthorImageSrc = "assets/img/noimage.png";
            }
          });
         
          
            this.firstTobicData=this.TopicsData[0];
          
       
           
        },error =>{
          console.log( "getLatestNews",error);
        }
      );
    }
  
      
  }
    
    
    
 

