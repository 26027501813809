import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { NewsDetailsComponent } from './components/pages/news-details/news-details.component';
import { VideosComponent } from './components/pages/videos/videos.component';
import { DatePipe } from '@angular/common'
import { VideoDetailsComponent } from './components/pages/video-details/video-details.component';
import { SafePipe } from './shared/safe.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WeatherConditionsComponent } from './components/pages/weather-conditions/weather-conditions.component';
import { ExchangeRatesComponent } from './components/pages/exchange-rates/exchange-rates.component';
import { TobicDetailsComponent } from './components/pages/tobic-details/tobic-details.component';
import { BreakingNewsComponent } from './components/pages/breaking-news/breaking-news.component';
import { TobicCategoryComponent } from './components/pages/tobic-category/tobic-category.component';
import { AuthorTobicComponent } from './components/pages/author-tobics/author-tobics.component';
 


@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    NavbarComponent,
    FooterComponent,
    PreloaderComponent,
    AboutComponent,


  
    FaqComponent,
    ErrorComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    ContactComponent,
    CategoryComponent,
    NewsDetailsComponent,
    VideosComponent,
    VideoDetailsComponent,
    WeatherConditionsComponent,
    ExchangeRatesComponent,
    SafePipe,
    TobicDetailsComponent,
    BreakingNewsComponent,
    TobicCategoryComponent,
    AuthorTobicComponent
     
  ],
  imports: [
    
    BrowserModule,
    AppRoutingModule ,
    HttpClientModule,
    BrowserAnimationsModule,
    
    NgbModule 
     
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
