

<section class="portfolio-area portfolio-area-two pt-100">
    <div class="container">
        <h2 class="section-category-title">
          فيدوهات وكالتنا
          </h2>
          

        <div class="row">
         

        <ng-container *ngFor="let videoItem of videosData">
            <div class="col-sm-6 col-lg-4">
                <div class="team-item">
                    <div class="category-video">
                        <iframe
                            class="category-video"
                            [src]="videoItem?.finalVideoURL | safe"
                            allow="autoplay"
                            frameborder="0"
                            scrolling="no"
                        ></iframe>
                    </div>
                    <div class="team-inner video-item-text">
                        <ul>
                            <li>
                                <a
                                    href="https://www.facebook.com/"
                                    target="_blank"
                                    ><i class="icofont-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/"
                                    target="_blank"
                                    ><i class="icofont-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.twitter.com/"
                                    target="_blank"
                                    ><i class="icofont-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/"
                                    target="_blank"
                                    ><i class="icofont-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                        <h3>
                            <a
                                class="video-title"
                                routerLink="/video-details/{{
                                    videoItem._id
                                }}"
                                >{{ videoItem.VideoTitle }}
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
        </ng-container>
        </div>

        <!--<div class="case-pagination">
            <ul>
                <li><a routerLink="/case-study"><i class="icofont-simple-left"></i></a></li>
                <li><a routerLink="/case-study">1</a></li>
                <li><a routerLink="/case-study">2</a></li>
                <li><a routerLink="/case-study">3</a></li>
                <li><a routerLink="/case-study"><i class="icofont-simple-right"></i></a></li>
            </ul>
        </div>-->
    </div>
</section>