<div class="page-title-area page-title-area-two title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2> تواصل معنا</h2>

                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>  تواصل معنا</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="contact-form contact-form-four pb-100">
    <div class="loaction-area">
        <div class="container">
            <div class="row location-bg">
                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-pin"></i>
                        </div>
                        <h3>المواقع</h3>
                        <ul>
                            <li>2108-267 Road Quadra,Toronto, Victiria Canada</li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-call"></i>
                        </div>

                        <h3>هاتف</h3>

                        <ul>
                            <li><a href="tel:+0755543332322">+02 2222 333</a></li>
                            <li><a href="tel:+0555962435736">+09 4444 5555</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-email"></i>
                        </div>
                        
                        <h3>البريد الالكتروني</h3>

                        <ul>
                            <li><a href="mailto:hello&#64;lufz.com">hello&#64;4media.com</a></li>
                            <li><a href="mailto:hello&#64;lufz.com">info&#64;4media.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <form id="contactForm">
            <div class="row contact-wrap">
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Your Full Name">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Your Email">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Your Phone">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message" placeholder="Case Description"></textarea>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="text-center">
                        <button type="submit" class="contact-btn">ارسل </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.72810813924!2d-0.24168018863114898!3d51.528771840455114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sbd!4v1591087986221!5m2!1sen!2sbd"></iframe>
</div>