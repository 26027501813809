import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  public categoryId:string;
  public categoryName:string;
  public newsList:any=[];
  constructor(private activatedRoute: ActivatedRoute, private mainService: MainService, private sanitizer: DomSanitizer) {

   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      params => {
        this.categoryId= params['categoryId'];
        this.categoryName= params['title'];
         this.mainService.getAllNewsByCategoryId(this.categoryId,"0").subscribe(
          data => {
            console.log(  "  data",data);
            this.newsList = JSON.parse(JSON.stringify(data));
           
          
            this.newsList.forEach(
              x=>{
              
               // x.ImageSrc= Buffer.from(x.ImageData).toString('base64');
               if(x.ImagePath  != null){
                x.ImageSrc = environment.apiUrl + `/Image/${x.ImagePath}`;//this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,`+ Buffer.from(x.ImageData).toString('base64'));
        
               }else{
                x.ImageSrc ="assets/img/4dlogo.png";
               }
        
              // x.ImageSrc= Buffer.from(x.ImageData).toString('base64');
               }
                
            );
            this.newsList.NewsListFirstRow=this.newsList.splice(0,2);
            this.newsList.NewsListOtherRows=this.newsList;
           console.log("  this.newsList",  this.newsList);
          },
          error => {
            // Handle errors
            console.log(  "   this.combinedData error",error);
          }  
        );
      });
           
  }

}
