import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weather-conditions',
 
  templateUrl: './weather-conditions.component.html',
  styleUrl: './weather-conditions.component.scss'
})
export class WeatherConditionsComponent implements OnInit {
  currentDate: any;

  constructor() { }
  ngOnInit(): void {
    var arabicDate=  new Date().toLocaleDateString('ar-PS', {day: 'numeric', month: 'short',weekday: 'long',year : 'numeric'});
 
 
    this.currentDate = arabicDate;

  }

}
