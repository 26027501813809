import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Video } from 'src/app/_models/Video';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss']
})
export class VideoDetailsComponent implements OnInit {
  public videoId:string;
  public videoDate:Video;
  public videoLink:string = '';
  videosData: Array<Video>=[];
  constructor(private activatedRoute: ActivatedRoute, private mainService: MainService , private sanitizer: DomSanitizer ) {
    }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      params => {  
        this.videoId= params['id'];
        this.mainService.getVideoById( this.videoId).subscribe(

          data=>{
          
           this.videoDate= JSON.parse(JSON.stringify(data));
            
             let linkCode = this.videoDate.VideoURL.split("=")[1];
         
         this.videoLink ="https://www.youtube.com/embed/"+ linkCode + "?autoplay=0";;
         console.log(this.videoLink);
            
          },error =>{
            console.log( "getLatestNews",error);
          }
        );
      });
   


    this.mainService.getVideos().subscribe(

      data=>{
      
      let videosData= JSON.parse(JSON.stringify(data));
    
     // let linkCode = this.videosData[0].VideoURL.split("=")[1];

    //  this.firstVideoLink= "https://www.youtube.com/embed/"+  linkCode+ "?autoplay=0";
       videosData.forEach(
          x=>{
            if(x.ImagePath  != null){
              x.ImageSrc =  environment.apiUrl + `/Image/${x.ImagePath}`;//this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,`+ Buffer.from(x.ImageData).toString('base64'));
      
             }else{
              x.ImageSrc ="assets/img/noimage.png";
             }
            // console.log(" this.videosData" , x);
            // let link = x.VideoURL.split("=")[1];
            // x.finalVideoURL= "https://www.youtube.com/embed/"+  link+ "?autoplay=0";
            // if(x.ImageData  != null){
            //  x.ImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,`+ Buffer.from(x.ImageData).toString('base64'));
     
            // }else{
            //  x.ImageSrc ="assets/img/video-play.jpg";
            // }
          }
        )
        this.videosData= videosData;
    
      },error =>{
        console.log( "getLatestNews",error);
      }
    );
      
  }

}
