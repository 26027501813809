import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { News } from 'src/app/_models/News';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { Category } from 'src/app/_models/Category';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {
public newsId:string;
public newsData:any;
public newsReletedList:any=[];
public videoLink:string='';
public categoriesList: Array<Category> =new Array<Category>();
  constructor(private activatedRoute: ActivatedRoute, private mainService: MainService, private sanitizer: DomSanitizer) {
 
    
   }

  ngOnInit(): void {
 
    this.activatedRoute.params.subscribe(
      params => {
        this.newsId= params['newsId'];
        this.mainService.getNewsById(this.newsId).subscribe(
          data => {
          
            this.newsData = JSON.parse(JSON.stringify(data));
            console.log(  "  this.newsData",  this.newsData);
            if(this.newsData.ImagePath  != null){
              this.newsData.ImageSrc =  environment.apiUrl + `/Image/${this.newsData.ImagePath}`;// this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,`+ Buffer.from(this.newsData.ImageData).toString('base64'));
              if(this.newsData.NewsVideoURL != null && this.newsData.NewsVideoURL != ''){
                let linkCode = this.newsData.NewsVideoURL.split("=")[1];
                this.videoLink ="https://www.youtube.com/embed/"+ linkCode;
              }
              
              this.mainService.getCategoryALlData().subscribe((data) => {
                this.categoriesList = JSON.parse(JSON.stringify(data));
            
           
          
              });
              //get releted news
        this.mainService.getAllNewsByCategoryId(this.newsData.CategoryId,"0").subscribe(
          data => {
          
            this.newsReletedList = JSON.parse(JSON.stringify(data));
            console.log(  "this.newsReletedList",this.newsReletedList);
            this.newsReletedList = this.newsReletedList.filter(item => item.NewsId != this.newsId);
                  
            this.newsReletedList.forEach(
              x=>{
              
               // x.ImageSrc= Buffer.from(x.ImageData).toString('base64');
              
               if(x.ImagePath  != null){
                x.ImageSrc =environment.apiUrl + `/Image/${x.ImagePath}`;// this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,`+ Buffer.from(x.ImageData).toString('base64'));
        
               }else{
                x.ImageSrc ="assets/img/noimage.png";
               }
               x.CategoryName =   this.categoriesList.find(a=>a.CategoryId== x.CategoryId)?.CategoryTitle;
              // x.ImageSrc= Buffer.from(x.ImageData).toString('base64');
               }
                
            );
           console.log("  this.newsReletedList",  this.newsReletedList);
          },
          error => {
            // Handle errors
            console.log(  "   this.combinedData error",error);
          }  
        );
             }else{
              this.newsData.ImageSrc ="assets/img/noimage.png";
             }
           
          },
          error => {
            // Handle errors
            console.log(  "   this.combinedData error",error);
          }  
        );
      });
   


  }



}
