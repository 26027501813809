<footer>
    <!--<div class="newsletter-area">
        <div class="container">
            <img src="assets/img/home-one/newsletter.png" alt="Shape">
            <h2>إشترك في قائمتنا البريدية</h2>

            <form class="newsletter-form">
                <input type="email" class="form-control" placeholder="*أدخل البريد الالكتروني" name="EMAIL" required autocomplete="off">
                <button class="btn contact-btn" type="submit">اشتراك</button>
            </form>
        </div>
    </div>-->

    <div class="container paddingtop-15">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"
                            ><img src="assets/img/logo/4dmedia_logo_1473.png" alt="Logo"
                        /></a>
                        <p>
                            {{companyData?.Introduction}}
                        </p>
                        <ul>
                            <li>
                                <a
                                href="{{companyData?.YoutubeURL}}" 
                                    target="_blank">
                                 
                                    <img src="assets/img/social-icons/icons8-youtube.svg" class="footer_social-icon"/>
                                </a>
                            </li>
                            <li>
                                <a
                                href="{{companyData?.FacebookURl}}" 
                                    target="_blank">
                                    <img src="assets/img/social-icons/icons8-facebook.svg" class="footer_social-icon"/>
                                </a>
                            </li>
                            <li>
                                <a
                                href="{{companyData?.InstagramURL}}" 
                                    target="_blank"
                                    > 
                                    <img src="assets/img/social-icons/icons8-instagram.svg" class="footer_social-icon"/>
                                </a>
                            </li>
                            <li>
                                <a
                                href="{{companyData?.TwitterURL}}" 
                                    target="_blank"
                                    >   
                                    <img src="assets/img/social-icons/icons8-x.svg" class="footer_social-icon"/>
                                </a>
                            </li>
                            <li>
                                <a
                                href="{{companyData?.TikTokURL}}" 
                                    target="_blank"
                                    > 
                                    <img src="assets/img/social-icons/icons8-tiktok.svg" class="footer_social-icon"/>
                                </a>
                            </li>
                            <li>
                                <a
                                href="{{companyData?.TelegramURL}}" 
                                    target="_blank"
                                    > 
                                    <img src="assets/img/social-icons/icons8-telegram.svg" class="footer_social-icon"/>
                                </a>
                            </li>
                            <li> 
                                <a
                                href="https://www.whatsapp.com/channel/{{companyData?.WhatsAppURL}}" 
                                    target="_blank"
                                    > 
                                    <img src="assets/img/social-icons/icons8-whatsapp.svg" class="footer_social-icon"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>القائمة</h3>

                        <ul>
                            <ng-container
                                *ngFor="let itemData of categoriesList"
                            >
                                <li>
                                    <a
                                        routerLink="/category/{{
                                            itemData.CategoryTitle
                                        }}/{{ itemData.CategoryId }}"
                                        ><i class="icofont-simple-right"></i>
                                        {{ itemData.CategoryTitle }}</a
                                    >
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>روابط سريعة</h3>

                        <ul>
                            <li>
                                <a routerLink="/"
                                    ><i class="icofont-simple-right"></i>
                                    الرئيسية</a
                                >
                            </li>
                            <li>
                                <a routerLink="about"
                                    ><i class="icofont-simple-right"></i>
                                    حولنا</a
                                >
                            </li>
                            <li>
                                <a routerLink="contact"
                                    ><i class="icofont-simple-right"></i> تواصل
                                    معنا</a
                                >
                            </li>
                            <li>
                                <a routerLink="contact"
                                    ><i class="icofont-simple-right"></i> اعلن
                                    معنا</a
                                >
                            </li>
                            <!-- <li>
                                <a routerLink="images"
                                    ><i class="icofont-simple-right"></i> صور</a
                                >
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>ابحث عنا</h3>

                        <ul>
                            <li>
                                <i class="icofont-location-pin"></i> 
                                 {{companyData?.Street}}/ {{companyData?.Neighborhood}}/ {{companyData?.City}}/ {{companyData?.Countery}}
                            </li>
                            <li>
                                <i class="icofont-ui-call"></i
                                ><a href="tel:{{companyData?.PhoneNumber}}"> {{companyData?.PhoneNumber}}</a>
                            </li>
                            <li>
                                <i class="icofont-at"></i>
                                <a href="mailto:{{companyData?.Email}}">
                                    {{companyData?.Email}}</a>
                                    <!-- hello&#64;4media.com</a -->
                                 
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-sm-7 col-lg-6">
                    <div class="copyright-item">
                        <p>
                            Copyright &#64;2023 <strong>4media</strong>. صصم بواسطة
                            <a href="https://4media.com/" target="_blank"
                                >4media</a
                            >
                        </p>
                    </div>
                </div>

                <div class="col-sm-5 col-lg-6">
                    <div class="copyright-item copyright-right">
                        <a routerLink="/terms-conditions" target="_blank"
                            >الشروط والأحكام</a
                        >
                        <span>-</span>
                        <a routerLink="/privacy-policy" target="_blank">
                            سياسة الخصوصية</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
