<div class="page-content page-container" id="page-content">
    <div class=" ">
        <div class="row   d-flex justify-content-center">
<div class="col-12 grid-margin stretch-card">
              <!--weather card-->
              <div class="card card-weather">
                <div class="card-body" style=" height: 17rem; ">
                  <div class="weather-date-location" style="text-align: left;">
                    <h3 style="    color: #037281;  ">{{ currentDate  }}</h3>
                    <p class="text-gray">
                     
                      <span class="weather-location">القدس</span>
                    </p>
                  </div>
                  <div class="weather-data d-flex">
                    <div class="mr-auto">
                      <h4 class="display-3">32
                        <span class="symbol">&deg;</span>C</h4>
                      <p>
                        غائم
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <div class="d-flex weakly-weather">
                    <div class="weakly-weather-item">
                      <p class="mb-0">
                        الاحد
                      </p>
                      <i class="mdi mdi-weather-cloudy"></i>
                      <p class="mb-0">
                        30&deg;
                      </p>
                    </div>
                    <div class="weakly-weather-item">
                      <p class="mb-1">
                        الاثنين
                      </p>
                      <i class="mdi mdi-weather-hail"></i>
                      <p class="mb-0">
                        31&deg;
                      </p>
                    </div>
                    <div class="weakly-weather-item">
                      <p class="mb-1">
                        الثلاثاء
                      </p>
                      <i class="mdi mdi-weather-partlycloudy"></i>
                      <p class="mb-0">
                        28&deg;
                      </p>
                    </div>
                    <div class="weakly-weather-item">
                      <p class="mb-1">
                        الاربعاء
                      </p>
                      <i class="mdi mdi-weather-pouring"></i>
                      <p class="mb-0">
                        30&deg;
                      </p>
                    </div>
                    <div class="weakly-weather-item">
                      <p class="mb-1">
                        الخميس
                      </p>
                      <i class="mdi mdi-weather-pouring"></i>
                      <p class="mb-0">
                        29&deg;
                      </p>
                    </div>
                    <div class="weakly-weather-item">
                      <p class="mb-1">
                        الجمعة
                      </p>
                      <i class="mdi mdi-weather-snowy-rainy"></i>
                      <p class="mb-0">
                        31&deg;
                      </p>
                    </div>
                    <div class="weakly-weather-item">
                      <p class="mb-1">
                        السبت
                      </p>
                      <i class="mdi mdi-weather-snowy"></i>
                      <p class="mb-0">
                        32&deg;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!--weather card ends-->
            </div>
            </div>
            </div>
            </div>


