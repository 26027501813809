import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
interface ExchangeRate {
  currency: string;
  rate: number;
}
@Component({
  selector: 'app-exchange-rates',

  templateUrl: './exchange-rates.component.html',
  styleUrl: './exchange-rates.component.scss'
})


export class ExchangeRatesComponent implements OnInit {
 
  USDRates: any;
  EURRates: any;
  JODRates: any;
  EGPRates: any;
  goldRates:any;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchExchangeRates();
  }

  fetchExchangeRates(): void {
 
      this.USDRates  = localStorage.getItem("USD"); 
    
      this.EURRates  = localStorage.getItem("EUR"); 
   
      this.JODRates  = localStorage.getItem("JOD"); 
   
      this.EGPRates  = localStorage.getItem("EGP"); 
    
  }
}
