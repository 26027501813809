import { HttpClient } from '@angular/common/http';
import { Component, OnInit,HostListener, EventEmitter, Output  } from '@angular/core';
import { Category } from 'src/app/_models/Category';
import { MainService } from 'src/app/_services/main.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Output() scrollTo: EventEmitter<void> = new EventEmitter<void>();
  isFixed = false;
  currentDate:any;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isFixed = offset > 100; // Adjust the offset value as needed
  }
  public categoriesList: Array<Category> =new Array<Category>();
  categoryListSource: Array<string> = [];
  companyData:any;
  adsInfo:any;
  constructor(    private mainService: MainService,private http: HttpClient) { }
 
  USDRates: any;
  EURRates: any;
  JODRates: any;
  EGPRates: any;
  goldRates:any;
  Rates:string="12,33";
  ngOnInit(): void {
    var arabicDate=  new Date().toLocaleDateString('ar-PS', {day: 'numeric', month: 'short',weekday: 'long',year : 'numeric'});
    this.currentDate= arabicDate;
    this. fetchExchangeRates();
    this.mainService.getCategoryALlData().subscribe((data) => {
      this.categoriesList = JSON.parse(JSON.stringify(data));
     console.log(  "   this.categoryCrationList", this.categoriesList);
    });
    this.mainService.GetAllContactInfo().subscribe((data) => {
      let ContactInfo= JSON.parse(JSON.stringify(data));
        this.companyData= ContactInfo[0];
       });
       this.mainService.GetAdsByOrder(1).subscribe((data) => {
        this.adsInfo= JSON.parse(JSON.stringify(data));
        console.log(" this.adsInfo  this.adsInfo  this.adsInfo",  this.adsInfo);
         if(this.adsInfo != null){
          this.adsInfo.link= this.adsInfo.VideoURL;
          if (  this.adsInfo.ImagePath != null) {
                  
            this.adsInfo.ImageSrc=  environment.apiUrl + `/Image/${this.adsInfo.ImagePath}`; 
          } else {
            
            this.adsInfo.ImageSrc = "assets/img/news/Video1728-90.gif";
          }
         }
            
         });
  
   }

  scrollToSection() {
    this.scrollTo.emit();
  }


  fetchExchangeRates(): void {
    this.USDRates  = localStorage.getItem("USD"); 
    
    this.EURRates  = localStorage.getItem("EUR"); 
 
    this.JODRates  = localStorage.getItem("JOD"); 
 
    this.EGPRates  = localStorage.getItem("EGP"); 
  }

}
