import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-author-tobics',
  templateUrl: './author-tobics.component.html',
  styleUrls: ['./author-tobics.component.scss']
})
export class AuthorTobicComponent implements OnInit {
  TopicsData:any=[];
  public authorId:string;
  public authorName:string;
  //public newsList:any=[];
  constructor(private activatedRoute: ActivatedRoute, private mainService: MainService, private sanitizer: DomSanitizer) {

   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      params => {
        this.authorId= params['id'];
        //this.categoryName= params['title'];
       
       this.selectTopic(this.authorId);
      });
           
  }



  
  selectTopic(authorId: string) {
    
    this.mainService.getAllTobicsByAuthorId(authorId).subscribe(
      data=>{
     
        this.TopicsData=  JSON.parse(JSON.stringify(data));
        console.log("this.TopicsData", this.TopicsData );
        if (this.TopicsData.AuthorImage != null) {
          
          this.TopicsData.AuthorImageSrc=  environment.apiUrl + `/Image/${this.TopicsData.AuthorImage}`; 
        } else {
          
          this.TopicsData.AuthorImageSrc = "assets/img/noimage.png";
        }

        this.TopicsData.SubjectsData.forEach(x => {
          if (x.ImagePath != null) {
            x.ImageSrc = environment.apiUrl + `/Image/${x.ImagePath}`;
           // x.AuthorImageSrc=  environment.apiUrl + `/Image/${x.AuthorImage}`; 
          } else {
            x.ImageSrc = "assets/img/noimage.png";
           // x.AuthorImageSrc = "assets/img/noimage.png";
          }
        });
        console.log( "test select    this.TopicsData",    this.TopicsData);
        
          //this.firstTobicData=this.TopicsData[0];
       
      },error =>{
        console.log( "TopicsData",error);
      }
    );
  }

}
