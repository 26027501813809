<div class="banner-area banner-img-oneCopy"  >
 
    <div class="container container-layout">
        <!-- <app-breaking-news></app-breaking-news> -->
  
        <div class="row row-margin home-div">
            
            <div class="col-lg-5 col-md-5  col-sm-12 col-xs-12  col-padding">
                <div  >
                    <div class="video-container-29" style=" margin-bottom: 2px; ">
                        <iframe
                            class="video-container-29"
                            [src]="liveLink | safe"
                            allow="autoplay"
                            frameborder="0"
                            scrolling="no"
                        ></iframe>
                    </div>
                   
                </div>

                
                    <div class="video-container-29">
                    <a
                    routerLink="/video-details/{{
                        firstVideoLink?._id
                    }}"
                    
                    >

                    <img
                    style="  max-height: 10rem;    width: 100%; "
                    class="img-fluid"
                    [src]="firstVideoLink?.ImageSrc"
                    alt="Blog"
                />
        
      
            <div class="card-body">
                <h6 class="card-title video-title" style="font-weight: bold">
                    {{firstVideoLink?.VideoTitle}}
                </h6>
            </div>
                </a>
                   
                  
                </div>
            </div>
  
            <div class="col-lg-7 col-md-7  col-sm-12 col-xs-12  col-padding">
                <ngb-carousel style="     background: #f0f0f0;   width: 100%;  height: 100%;">
                        
                    <ng-template ngbSlide *ngFor="let item of sliderNews">
                        <a
                        routerLink="/news/{{ item?.CategoryName }}/{{
                            item?.CategoryName
                        }}/{{ item?.NewsId }}"
                    >
                      <div class="picsum-img-wrapper">
                        <img  class="img-fluid" [src]="item.ImageSrc" alt="Random first slide">
                      </div>
                    </a>
                
                     
                      <div class="carousel-caption">
                        <a
                        routerLink="/news/{{ item?.CategoryName }}/{{
                            item?.CategoryName
                        }}/{{ item?.NewsId }}"
                    >
                        <p>{{ item.NewsTitle}}</p>
                    </a>
                      </div>
                 
                    </ng-template>
                  </ngb-carousel>

            </div>
        </div>
      
         <div class="row row-margin"  >
           
            <ng-container *ngFor="let newsItem of latestNews">
                <div
                    class="col-md-4 col-sm-6 col-xs-12"
                    style="margin-bottom: 7px"
                >
                    <div class="card card-home">
                        <a
                            routerLink="/news/{{ newsItem?.CategoryName }}/{{
                                newsItem?.CategoryName
                            }}/{{ newsItem?.NewsId }}"
                        >
                            <div class="card-body" style="background: none">
                                <h5 class="category-header">
                                      {{ newsItem.CategoryName }}
                                </h5>
                                <div class="row">
                                    <div
                                        class="col-lg-5 col-md-5 col-xs-12 card-img-new"
                                    >
                                        <img
                                            style="  max-height: 8rem;"
                                            class="img-fluid"
                                            [src]="newsItem?.ImageSrc"
                                            alt="Blog"
                                        />
                                    </div>
                                    <div
                                        class="col-lg-7 col-md-7 col-xs-12 card-text"
                                    >
                                        <h6
                                            class="news-title"
                                            style="font-weight: bold"
                                        >
                                            {{ newsItem?.NewsTitle }}
                                        </h6>

                                        <p class="news-content">
                                            {{ newsItem?.NewsIntroduction }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
               
            </ng-container>
        </div>

        <br />
    </div>

    <div class="container">
        <div class="row row-margin">
            <div class=" col-lg-12" style="margin: 6px" *ngIf="advsTwo ==null">
                <a href="#"><img class="td-retina img-responsive" src="assets/img/news/Video21290-180.gif" alt=""></a>
            </div>

            <div class=" col-lg-12" style="margin: 6px" *ngIf="advsTwo !=null">
                <a  href="{{advsTwo.link}}" target="_blank"><img class="td-retina img-responsive"      [src]="advsTwo?.ImageSrc" alt=""></a>
            </div>
           
        </div>
    </div>
</div>
<section class="team-area">
    <div class="container">
        <div class="b-t1 pa6">
        <a routerLink="/videos">
            <h4 class="category-header">فيدوهات</h4>
            
        </a>
    </div>
       

        <div class="row  pb-70" >
            <ng-container *ngFor="let videoItem of videosData">

                <div
                class="col-md-3 col-sm-6 col-xs-12"
                style="margin-bottom: 7px"
            >
                <div class="card card-home">
                    <a
                    routerLink="/video-details/{{
                        videoItem._id
                    }}"
                    >
                        <div class="card-body" style="background: none">
                           
                            <div class="row">
                                <div
                                class="col-lg-6 col-md-6 col-xs-12 card-text"
                            >
                                <h6
                                    class="news-title"
                                    style="font-weight: bold;     -webkit-line-clamp: 4;    "
                                >
                                    {{ videoItem?.VideoTitle }}
                                </h6>

                                 
                            </div>
                                <div
                                    class="col-lg-6 col-md-6 col-xs-12 card-img-new"
                                >
                              
                                <img
                                    style="  max-height: 10rem;"
                                    class="img-fluid"
                                    [src]="videoItem?.ImageSrc"
                                    alt="Blog"
                                />
                        
                                </div>
                              
                            </div>
                        </div>
                    </a>
                </div>
            </div>



              
            </ng-container>
        </div>


        <div class="row">
            <div class=" pa6">
                <a routerLink="/">
                    <h4 class="category-header">موضوعاتنا
                        <i class="fa fa-sort-desc" aria-hidden="true"></i>
                    </h4>
                </a>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12">
            <div class=" p-5 bg-white rounded shadow mb-5" style="height: 33rem;     max-height: 100%;  ">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link f-bold active "  (click)="selectTopic('1')"  id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"> مقالات  </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link f-bold" id="profile-tab"  (click)="selectTopic('2')" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">ترجمات وردود</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link f-bold" id="contact-tab"  (click)="selectTopic('3')"data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">مدونات</button>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <br>
                       <div class="row">
                        <ng-container *ngFor="let item of  TopicsData; let i = index;">
                            <div class="col-md-6">
                              
                                <div class="card-article ">
                                    <img  class="card-avatar-img"   [src]="item?.AuthorImageSrc"   aria-hidden="true">
                                        <a class="card-article-link " routerLink="/TDetails/{{ item?.SubjectId }}">
                                        <div class="card-article-title"><span>  {{item.SubjectTitle}}</span></div>
                                        <div> 
                                            <span class="card-article-writer"> {{item.AuthorName}}   </span></div>
                                        </a> 
                                </div>
                            
                            </div>
                        
                        </ng-container>
                    
                    
                       </div>
                       <div class="more-link-container">
                    
                        <a  routerLink="/tobics/1" class="more-link">اقرأ المزيد</a>
                    </div>
                      
     
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <br>
                        <div class="row" *ngIf="firstTobicData != null">
                             
                            <div class="col-md-6 card-article-link">
                                <div class="card" style="width: 100%; border: none !important;">
                                    <a routerLink="/TDetails/{{ firstTobicData?.SubjectId }}">  <div class="responsive-image">
                                    <img class="card-img-top  "   [src]="firstTobicData?.ImageSrc" alt="Card image cap">
                                </div>
                                    <div class="card-body" style="background: none">
                                      <h5 class="card-text news-content" style=" font-size: larger;">
                                        {{firstTobicData.SubjectTitle}}                                      </h5>
                                    </div>
                                    </a>
                                  </div>
                            </div>
                       
                        <ng-container *ngFor="let item of  TopicsData.slice(1); let i = index;">
                            <div class="col-md-6 card-article-link">
                                <div class="card" style="    border: none !important;">
                                    <a routerLink="/TDetails/{{ item?.SubjectId }}">
                                        <div class="card-body" style="background: none">
                                             
                                            <div class="row">
                                                <div
                                                    class="col-lg-5 col-md-5 col-xs-12 card-img-new"
                                                >
                                                    <img
                                                        style="  max-height: 10rem;"
                                                        class="img-fluid responsive-image"
                                                        [src]="item?.ImageSrc"
                                                        alt="Blog"
                                                    />
                                                </div>
                                                <div
                                                    class="col-lg-7 col-md-7 col-xs-12 card-text"
                                                >
                                                    <h6
                                                        class="  news-content"
                                                        style="font-weight: bold"
                                                    >
                                                    {{item.SubjectTitle}}                   
                                                    </h6>
            
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                             
                            </div>
                        </ng-container>
                        </div>
                        <div class="more-link-container"  >
                            <a  routerLink="/tobics/2" class="more-link">اقرأ المزيد</a>
                        </div>
                          
                    </div>
                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <br>
                        <div class="row" *ngIf="firstTobicData != null">
                             
                            <div class="col-md-6 card-article-link">
                                <div class="card" style="width: 100%; border: none !important;">
                                    <a routerLink="/TDetails/{{ firstTobicData?.SubjectId }}">  <div class="responsive-image">
                                    <img class="card-img-top  "   [src]="firstTobicData?.ImageSrc" alt="Card image cap">
                                </div>
                                    <div class="card-body" style="background: none">
                                      <h5 class="card-text news-content" style=" font-size: larger;">
                                        {{firstTobicData.SubjectTitle}}                                      </h5>
                                    </div>
                                    </a>
                                  </div>
                            </div>
                       
                        <ng-container *ngFor="let item of  TopicsData.slice(1); let i = index;">
                            <div class="col-md-6 card-article-link">
                                <div class="card" style="    border: none !important;">
                                    <a routerLink="/TDetails/{{ item?.SubjectId }}">
                                        <div class="card-body" style="background: none">
                                             
                                            <div class="row">
                                                <div
                                                    class="col-lg-5 col-md-5 col-xs-12 card-img-new"
                                                >
                                                    <img
                                                        style="  max-height: 10rem;"
                                                        class="img-fluid responsive-image"
                                                        [src]="item?.ImageSrc"
                                                        alt="Blog"
                                                    />
                                                </div>
                                                <div
                                                    class="col-lg-7 col-md-7 col-xs-12 card-text"
                                                >
                                                    <h6
                                                        class="  news-content"
                                                        style="font-weight: bold"
                                                    >
                                                    {{firstTobicData.SubjectTitle}}                   
                                                    </h6>
            
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                             
                            </div>
                        </ng-container>
                    </div>
                        <div class="more-link-container" >
                            <a  routerLink="/tobics/3" class="more-link">اقرأ المزيد</a>
                        </div>
                   
                    </div>
                  </div>
            </div>

            <div class="header-banner" *ngIf="advsThree == null">
                <a href="#"
                    ><img
                        style="width: 100%"
                        class="td-retina img-responsive"
                        src="assets/img/news/Video1728-90.gif"
                        alt=""
                /></a>
            </div>
            <div class="header-banner" *ngIf="advsThree != null">
                <a href="{{advsThree.link}}" target="_blank"
                    ><img
                        style="width: 100%"
                        class="td-retina img-responsive"
                        [src]="advsThree?.ImageSrc"
                        alt=""
                /></a>
            </div>
        </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <app-weather-conditions></app-weather-conditions>
                <app-exchange-rates></app-exchange-rates>
            </div>
        </div>
    </div>
</section>
 
<ng-container *ngFor="let item of CategoryNewsData">
    <section class="practice-area pt-100 pb-70">
        <div class="container">
            <div class="section-header">
                <a
                    routerLink="/category/{{ item.CategoryTitle }}/{{
                        item.CategoryId
                    }}"
                >
                    <h2 class="section-title">
                        {{ item.CategoryTitle }}
                    </h2>
                </a>
                <div class="section-more-link">
                    <a
                        routerLink="/category/{{ item.CategoryTitle }}/{{
                            item.CategoryId
                        }}"
                    >
                        <span class="more-text">المزيد</span>
                        <svg
                            class="more-icon"
                            width="8"
                            height="13"
                            viewBox="0 0 8 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.90747 11.3642L3.35632 6.63623L7.90747 1.90823L6.68305 0.63623L0.907471 6.63623L6.68305 12.6362L7.90747 11.3642Z"
                                fill="white"
                            ></path>
                        </svg>
                    </a>
                </div>
            </div>
            <!--<div class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Our Legal Practices Areas</h2>
        </div>-->
        <ng-container
        *ngIf="
            item.NewsListFirstRow &&
            item.NewsListFirstRow.length > 0
        "
    >
            <div class="row">
             
                    <div class="col-lg-8  col-md-12 col-sm-12 border-b">
                        <a
                            routerLink="/news/{{ item.CategoryTitle }}/{{
                                item.CategoryTitle
                            }}/{{ item.NewsListFirstRow[0].NewsId }}"
                        >
                            <div>
                                <div
                                    class="practice-item"
                                    
                                >
                                    <div>
                                        <img
                                        class="img-fluid img-size100"
                                            [src]="
                                                item.NewsListFirstRow[0]
                                                    .ImageSrc
                                            "
                                            alt="Shape"
                                           
                                        />
                                    </div>
                                    <h3>
                                        {{ item.NewsListFirstRow[0].NewsTitle }}
                                    </h3>
                                    <p>
                                        {{
                                            item.NewsListFirstRow[0]
                                                .NewsIntroduction
                                        }}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div
                        class=" col-lg-4  col-md-6 col-sm-12   border-b"
                        *ngIf="item.NewsListFirstRow.length > 1"
                    >
                        <a
                            routerLink="/news/{{ item.CategoryTitle }}/{{
                                item.CategoryTitle
                            }}/{{ item.NewsListFirstRow[1]?.NewsId }}"
                        >
                            <div class="practice-item img-fluid"  >
                                <img
                                
                                class="img-fluid"
                                    [src]="item.NewsListFirstRow[1]?.ImageSrc"
                                    alt="Shape"
                                  
                                />

                                <h5 class="news-title">
                                    {{ item.NewsListFirstRow[1]?.NewsTitle }}
                                </h5>
                                <p>
                                    {{
                                        item.NewsListFirstRow[1]
                                            ?.NewsIntroduction
                                    }}
                                </p>
                            </div>
                        </a>
                        <a
                            routerLink="/news/{{ item.CategoryTitle }}/{{
                                item.CategoryTitle
                            }}/{{ item.NewsListFirstRow[2]?.NewsId }}"
                        >
                            <div
                                class="practice-item"
                                
                                *ngIf="item.NewsListFirstRow.length >= 2"
                            >
                                <img
                                 
                             class="img-fluid"
                                    [src]="item.NewsListFirstRow[2]?.ImageSrc"
                                    alt="Shape"
                                   
                                />

                                <h5 class="news-title">
                                    {{ item.NewsListFirstRow[2]?.NewsTitle }}
                                </h5>
                                <p>
                                    {{
                                        item.NewsListFirstRow[2]
                                            ?.NewsIntroduction
                                    }}
                                </p>
                            </div>
                        </a>
                    </div>
                
            </div>
        </ng-container>
                <div class="row">
                    <ng-container
                        *ngFor="let subItem of item.NewsListOtherRows"
                    >
                        <div class=" col-lg-4 col-md-6 col-sm-12">
                            <a
                                routerLink="/news/{{ item.CategoryTitle }}/{{
                                    item.CategoryTitle
                                }}/{{ subItem.NewsId }}"
                                target="_blank"
                            >
                                <div class="practice-item">
                                    <div>
                                        <img
                                            class=" img-fluid news-cover-img"
                                            [src]="subItem.ImageSrc"
                                            alt="Shape"
                                             
                                        />
                                    </div>
                                    <h5 class="news-title">
                                        {{ subItem.NewsTitle }}
                                    </h5>
                                    <p>{{ subItem.NewsIntroduction }}</p>
                                </div>
                            </a>
                        </div>
                    </ng-container>
                </div>
         
        </div>
    </section>
</ng-container>

<br />
<br />

