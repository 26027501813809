<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img
                src="assets/img/logo/logovideo.gif"
                style="height: 42px; float: left"
                alt="Logo"
            />
        </a>
    </div>
    <div class="top_banner_wrap">
        <div
            class="container-fluid header-info"
            style="box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3); padding: 1px;"
        >
            <div class="container">
                <div class="row">
                    <div class="col-3">
                        <div
                            class="home-date"
                            style="display: flex; height: 100%"
                        >
                            <img
                                style="
                                    margin-top: 2px;
                                    height: 23px;
                                    margin-top: 7px;
                                "
                                src="assets/img/icons/calendar.svg"
                            /> 
                            <p class="currect-date" style="margin: 5px 1px 0 0">
                                {{ currentDate }}
                            </p>
                        </div>
                    </div>

                    <div class="col-5">
                        <ul class="currency-list">
                            <li style="border-left: 1px solid #ccc;">
                               
                                <b class="currency-header_title">دولار أمريكي</b>

                                <span class="currency-header_price">{{ USDRates }} شيكل</span>
                            </li>
                            <li  style="border-left: 1px solid #ccc;">
                               
                                <b class="currency-header_title">دينار أردني</b>
                                <span class="currency-header_price" style="padding-left: 3px;">{{ JODRates }} شيكل</span>
                            </li>
                            <li  style="border-left: 1px solid #ccc;">
                               
                                 
                                <b class="currency-header_title">اليورو الأوروبي</b>
                                <span class="currency-header_price">{{ EURRates }} شيكل</span>
                            </li>
                            <li>
                              
                                  
                                <b class="currency-header_title">الجنيه المصري</b>
                                <span class="currency-header_price">{{ EGPRates }} شيكل</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-2">
                        <div class="header-weather">
                            <div class="header-weather-city">
                                <select name="city" class="weather-cities-list">
                                    <option
                                        selected="selected"
                                        value="jerusalem"
                                    >
                                        القدس
                                    </option>
                                    <option value="ramallah">رام الله</option>
                                    <option value="jericho">أريحا</option>
                                    <option value="bethlehem">بيت لحم</option>
                                    <option value="beersheba">بئر السبع</option>
                                    <option value="gaza">غزة</option>
                                    <option value="hebron">الخليل</option>
                                    <option value="jenin">جنين</option>
                                    <option value="tubas">طوباس</option>
                                    <option value="nablus">نابلس</option>
                                    <option value="rafah">رفح</option>
                                    <option value="khan-yunus">خان يونس</option>
                                    <option value="qalqilya">قلقيلية</option>
                                    <option value="tulkarm">طولكرم</option>
                                    <option value="salfit">سلفيت</option>
                                </select>
                                <div class="header-weather-degree">31º</div>
                                <div class="header-weather-icon">
                                    <span class="wi wi-yahoo-32"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="soical-header">
                            <ul style="margin: 0; padding: 0;">
                                <li>
                                    <a
                                    href="{{companyData?.YoutubeURL}}" 
                                        target="_blank">
                                     
                                        <img src="assets/img/social-icons/icons8-youtube.svg" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                    href="{{companyData?.FacebookURl}}" 
                                        target="_blank">
                                        <img src="assets/img/social-icons/icons8-facebook.svg"  />
                                    </a>
                                </li>
                                <li>
                                    <a
                                    href="{{companyData?.InstagramURL}}" 
                                        target="_blank"
                                        > 
                                        <img src="assets/img/social-icons/icons8-instagram.svg"  />
                                    </a>
                                </li>
                                <li>
                                    <a
                                    href="{{companyData?.TwitterURL}}" 
                                        target="_blank"
                                        >   
                                        <img src="assets/img/social-icons/icons8-x.svg" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                    href="{{companyData?.TikTokURL}}" 
                                        target="_blank"
                                        > 
                                        <img src="assets/img/social-icons/icons8-tiktok.svg"  />
                                    </a>
                                </li>
                                <li>
                                    <a
                                    href="{{companyData?.TelegramURL}}" 
                                        target="_blank"
                                        > 
                                        <img src="assets/img/social-icons/icons8-telegram.svg" />
                                    </a>
                                </li>
                                <li> 
                                    <a
                                    href="https://www.whatsapp.com/channel/{{companyData?.WhatsAppURL}}" 
                                        target="_blank"
                                        > 
                                        <img src="assets/img/social-icons/icons8-whatsapp.svg"  />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-3 col-sm-3">
                    <!-- <div class="home-date" style="display: flex;">
                    <img style="    margin-top: 2px;height: 23px; " src="assets/img/icons/ion--calendar-sharp.svg">
                    <p class="currect-date" style="margin: 0 1px 0 0;">{{currentDate}}</p>
                </div>
                -->

                    <div class="header-logo">
                        <a href="#">
                            <img
                                class="td-retina-data img-responsive"
                                src="assets/img/logo/logovideo.gif"
                                alt=""
                            />
                        </a>
                    </div>
                </div>
                <div class="col-xs-9 col-md-9 col-sm-9 hidden-xs">
                    <div class="header-banner" *ngIf="adsInfo == null">
                        <a href="#"
                            ><img
                                style="width: 100%"
                                class="td-retina img-responsive"
                                src="assets/img/news/Video1728-90.gif"
                                alt=""
                        /></a>
                    </div>
                    <div class="header-banner" *ngIf="adsInfo != null">
                        <a href="{{adsInfo.link}}" target="_blank"
                            ><img
                                style="width: 100%"
                                class="td-retina img-responsive"
                                [src]="adsInfo?.ImageSrc"
                                alt=""
                        /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-nav" [ngClass]="{ 'fixed-top': isFixed }">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <!---  <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/almasaderLogo.jpeg" alt="Logo" width="25%">
                </a>-->

                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav">
                        <li class="nav-item" id="logo-nav-item">
                            <a routerLink="/" class="logo">
                                <img
                                    src="assets/img/logo/4dmedia_logo_1473.png"
                                    height="50px"
                                    width="52px"
                                    alt="Logo"
                                    style="margin: -13px; float: right"
                                />
                            </a>
                        </li>
                        <ng-container *ngFor="let item of categoriesList">
                            <li class="nav-item">
                                <a
                                    routerLink="/category/{{
                                        item.CategoryTitle
                                    }}/{{ item.CategoryId }}"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: false }"
                                >
                                    {{ item.CategoryTitle }}</a
                                >
                            </li>
                        </ng-container>

                        <li class="nav-item">
                            <a
                                href="javascript:void(0)"
                                class="nav-link dropdown-toggle"
                                >المزيد</a
                            >

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/videos"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >فيديوهات</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/about"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >حول</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/contact"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >تواصل معنا</a
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
