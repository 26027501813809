 

<div class="case-details-img pt-100 pb-70">
    <div class="container">
       
     
        <div class="row ">
            <div class="col-lg-9 col-md-9 col-sm-12">
                <h2 style="    font-weight: bold; "> {{tobicData?.SubjectTitle}}</h2>
                <br>
                <div style="display: flex;">
                  
                    <a   routerLink="/authortobics/{{ tobicData?.AuthorId }}" >
                        <img  class="card-avatar-img" [src]="tobicData?.AuthorImageSrc"    aria-hidden="true">
                    <p   style="font-weight: bold;     display: inline;     margin-right: 7px;  "> بقلم:  {{tobicData?.AuthorName}}   </p>
                    </a>
                   
                </div>
              
                <p style=" margin-right:  32px">{{tobicData?.InsertDate  | date:'yyyy-MM-dd'}}</p>
                <div class="case-details-item">
                    <img [src]="tobicData?.ImageSrc"   alt="Case">
                </div>
               
                <div class="case-details-item">
                     
                        <div  [innerHTML]="tobicData?.SubjectContent"></div>
                 
                    
                </div>

                <div class="case-details-item" *ngIf="videoLink">
                    <div class="video-container">
                         <iframe *ngIf="videoLink" width="100%" height="100%" [src]="videoLink  | safe" frameborder="0" allowfullscreen></iframe>
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12" style="  margin-top: 9rem; ">
                <div class="block-advertisement">  
                    <b> منطقة اعلان 208*306</b>
                </div>
               <div class="block-news" *ngIf="releatedTopicsData != null && releatedTopicsData.length>0">
                <div class=" pa6">
                    <a routerLink="/" class="bb1">
                        <h2 class="tobic-header">اقرأ ايضا
                            <i class="fa fa-sort-desc" aria-hidden="true"></i>
    
                        </h2>
                        
                    </a>
                </div>
                <div class="  card-article-link">
                    <ng-container *ngFor="let item of  releatedTopicsData; let i = index;">
                    <div class="card" style="    border: none !important;">
                        <a class="card-article-link bb1gray " routerLink="/TDetails/{{ item?.SubjectId }}">
                       
                            <div class="card-body" style="background: none">
                                 
                                <div class="row">
                                    <div
                                        class="col-lg-5 col-md-5 col-xs-12 card-img-new"
                                    >
                                        <img
                                            style="  max-height: 10rem;"
                                            class="img-fluid responsive-image"
                                            [src]="item?.ImageSrc" 
                                            alt="Blog"
                                        />
                                    </div>
                                    <div
                                        class="col-lg-7 col-md-7 col-xs-12 card-text"
                                    >
                                        <h6
                                            class="  news-content"
                                            style="font-weight: bold"
                                        >
                                        {{item?.SubjectTitle}}   
                                        </h6>

                                       
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-container>
               
                   
                </div>
               </div>
            </div>
        </div>
       
    </div>
</div>
 >

