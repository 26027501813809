import { Component, OnInit } from '@angular/core';
import { Category } from 'src/app/_models/Category';
import { MainService } from 'src/app/_services/main.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public categoriesList: Array<Category> =new Array<Category>();
  categoryListSource: Array<string> = [];
  companyData:any;
  constructor(    private mainService: MainService) { }


  ngOnInit(): void {
    this.mainService.getCategoryALlData().subscribe((data) => {
      let dataSourse= JSON.parse(JSON.stringify(data));
       this.categoriesList= dataSourse.slice(0,5);
    });
    this.mainService.GetAllContactInfo().subscribe((data) => {
   let ContactInfo= JSON.parse(JSON.stringify(data));
     this.companyData= ContactInfo[0];
    });

  }

}
