 

<div class="case-details-img pt-100 pb-70">
    <div class="container">
     
         
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12">
                <h2 style="    font-weight: bold; "> {{newsData?.NewsTitle}}</h2>
                <div class="case-details-item">
                    <img [src]="newsData?.ImageSrc"   alt="Case">
                </div>
                <div style="display: flex;">
                     <p style=" margin-right:  32px">{{newsData?.InsertDate  | date:'yyyy-MM-dd'}}</p>
                </div>
                <br>
                <div class="case-details-item">
                     
                        <div  [innerHTML]="newsData?.NewsContent"></div>
                   
                </div>
                 
                <div class="case-details-item" *ngIf="videoLink">
                    <div class="video-container">
                         <iframe *ngIf="videoLink" width="100%" height="100%" [src]="videoLink  | safe" frameborder="0" allowfullscreen></iframe>
                         </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12" style="  margin-top: 6rem; ">
                <div class="block-advertisement">  
                    <b> منطقة اعلان 208*306</b>
                </div>
               <div class="block-news" *ngIf="newsReletedList.length > 0">
                <div class=" pa6">
                    <a routerLink="/" class="bb1">
                        <h2 class="tobic-header">الاكثر قراءة 
                            <i class="fa fa-sort-desc" aria-hidden="true"></i>
    
                        </h2>
                        
                    </a>
                </div>
                <div class="  card-article-link" *ngIf="newsReletedList.length > 0">
                    <ng-container *ngFor="let item of  newsReletedList; let i = index;">
                    <div class="card" style="    border: none !important;">
                        <a  routerLink="/news/{{item?.CategoryName}}/{{item?.CategoryName}}/{{item?.NewsId}}"   class="bb1gray nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" >
                        
                            <div class="card-body" style="background: none">
                                 
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-xs-12 card-img-new"   >
                                        <img
                                            style="  max-height: 10rem;"
                                            class="img-fluid responsive-image"
                                            [src]="item?.ImageSrc" 
                                            alt="Blog"
                                        />
                                    </div>
                                    <div class="col-lg-7 col-md-7 col-xs-12 card-text" >
                                        <h6
                                            class="  news-content"
                                            style="font-weight: bold"
                                        >
                                        {{item.NewsTitle}}   
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-container>
                </div>
               </div>
            </div>
        </div>
       
    </div>
</div>

 

<section class="portfolio-area portfolio-area-three pt-100" *ngIf="newsReletedList.length >0">
    <div class="container">
        <div class="section-title">
            <h2> أقرأ ايضا </h2>
        </div>

        <div class="row">
            <ng-container *ngFor="let newsData of newsReletedList">
                <div class="col-sm-6 col-lg-4">
                    <a  routerLink="/news/{{newsData?.CategoryName}}/{{newsData?.CategoryName}}/{{newsData?.NewsId}}"   class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" >
                    <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s" style="    height: 28rem;">
                        <img [src]="newsData?.ImageSrc"  alt="Portfolio" height="60%">
    
                        <div class="portfolio-inner">
                            <span style="font-weight: bold;"> {{newsData?.NewsTitle}}</span>
                            <p class="title-releted-news">    {{newsData?.NewsIntroduction}}</p>
                           <!--  <p>10 Feb d2021</p>-->
                        </div>
                    </div>
                    </a>
                </div>
            </ng-container>

         
 
        </div>
    </div>
</section>