import { News } from "./News";

export class Category {
    CategoryTitle:string;
    CategoryId:number;
    InsertDate:Date;
    InsertDateString:any;
    Order:number;
    Show:number;
  }

  export class  CategoryNews{
    CategoryTitle:string;
    CategoryId:number;
    NewsList:Array<News>;
    NewsListFirstRow:Array<News>;
    NewsListOtherRows:Array<News>;

   constructor(){
      this.NewsList=new  Array<News>();
      this.NewsListFirstRow=new  Array<News>();
      this.NewsListOtherRows=new  Array<News>();
  }
  }