import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Video } from 'src/app/_models/Video';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { DatePipe } from '@angular/common'
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  videosData: Array<Video>=[];
  constructor( private mainService: MainService , private sanitizer: DomSanitizer, public datepipe: DatePipe) { }

  ngOnInit(): void {
     
    this.mainService.getVideos().subscribe(

      data=>{
      
       this.videosData= JSON.parse(JSON.stringify(data));
       
       this.videosData.forEach(
          x=>{
           x.InsertDateString= this.datepipe.transform(x.InsertDate, 'yyyy-MM-dd'); 
           let link = x.VideoURL.split("=")[1];
              x.finalVideoURL= "https://www.youtube.com/embed/"+  link+ "?autoplay=0";
            if(x.ImagePath  != null){
             x.ImageSrc = environment.apiUrl + `/Image/${x.ImagePath}`;//this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,`+ Buffer.from(x.ImageData).toString('base64'));
     
            }else{
             x.ImageSrc ="assets/img/video-play.jpg";
            }
          }
        )
    console.log("this.videosData", this.videosData);
      },error =>{
        console.log( "getLatestNews",error);
      }
    );
  }

}
