<section class="practice-area pt-100 pb-70">
    <div class="container">

       
            <h2 class="section-category-title">
              {{categoryName}}
            </h2>
            
        <!--<div class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Our Legal Practices Areas</h2>
        </div>-->

        <div class="row  border-b">
            <ng-container  *ngIf="newsList.NewsListFirstRow && newsList.NewsListFirstRow.length > 0"  >
            <div class="col-sm-6 col-lg-8">
                <a  routerLink="/news/{{categoryName}}/{{categoryName}}/{{newsList.NewsListFirstRow[0].NewsId}}"  >
                <div class="practice-item">
                    <div  >
                        
                        <img  class="img-fluid"  style="width: 100%;   max-height: 27rem;" [src]="newsList.NewsListFirstRow[0].ImageSrc"  alt="Shape"   >
                    </div>
                    <h3> {{newsList.NewsListFirstRow[0]?.NewsTitle}}</h3>
                    <p>  {{newsList.NewsListFirstRow[0]?.NewsIntroduction}}</p> 
                   
                    
                </div>
            </a>
            </div>

            <div class="col-sm-6 col-lg-4"  *ngIf=" newsList.NewsListFirstRow.length >  1">
                <a routerLink="/news/{{categoryName}}/{{categoryName}}/{{newsList.NewsListFirstRow[1]?.NewsId}}">

                <div class="practice-item">
                    <div  >
                        <img  class="news-cover-img" [src]="newsList.NewsListFirstRow[1]?.ImageSrc"  alt="Shape" width= "100%;"   height= "238px;">
                    </div>
                    <h3 class="news-title" style="  line-height: 32px;  "> {{newsList.NewsListFirstRow[1]?.NewsTitle}}</h3>
                    <p>  {{newsList.NewsListFirstRow[1]?.NewsIntroduction}}</p> 
                </div>
                </a>
            </div>
            

        </ng-container>


 
        </div>
        <div class="row">
            <ng-container *ngFor="let subItem of newsList.NewsListOtherRows">

            <div class="col-sm-6 col-lg-4">
                <a  routerLink="/news/{{categoryName}}/{{categoryName}}/{{subItem.NewsId}}" >

                <div class="practice-item">
                    <div  >
                        <img class="news-cover-img" [src]="subItem?.ImageSrc"   alt="Shape"  width="238px;">

                    </div>
                    <h5 class="news-title" style="  line-height: 32px;  ">  {{subItem.NewsTitle}}</h5>
                    <p>  {{subItem.NewsIntroduction}}</p>
                   
                </div>
                </a>
            </div>
            
    </ng-container>
        </div>
    </div>
</section>